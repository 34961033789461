import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ImgFallbackDirective } from '@openreel/frontend/common/directives/img-fallback.directive';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'or-preview-thumb',
  templateUrl: './preview-thumb.component.html',
  styleUrls: ['./preview-thumb.component.scss'],
  animations: [
    trigger('showHide', [
      // ...
      state(
        'show',
        style({
          opacity: 1.0,
        })
      ),
      state(
        'hide',
        style({
          opacity: 0.0,
        })
      ),
      transition('show => hide', [animate('0.1s')]),
      transition('hide => show', [animate('0.4s')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule, ImgFallbackDirective],
})
export class PreviewThumbComponent {
  private _thumbUrl: string;
  @Input()
  set thumbUrl(url: string) {
    this.imgSrc.next(url);
    this._thumbUrl = url;
  }
  get thumbUrl() {
    return this._thumbUrl;
  }

  @Input() previewUrl: string;
  @Input() showPlayButton = true;
  @Input() showDetailsButton = false;
  @Input() showPermissionIcon = false;
  @Input() isVideoShared = true;
  @Input() alt = '';

  @Output() playButtonClick = new EventEmitter<MouseEvent>();
  @Output() viewDetailsButtonClick = new EventEmitter<MouseEvent>();

  private imgSrc = new ReplaySubject<string>(1);
  showControls = false;
  imgSrc$ = this.imgSrc.pipe(
    filter((s) => !!s),
    distinctUntilChanged()
  );

  imgLoaded = false;

  onPlayClick($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    this.showControls = false;
    this.playButtonClick.emit();
  }

  onViewDetailsClick(event: MouseEvent): void {
    this.viewDetailsButtonClick.emit(event);
  }

  setThumb(src: string = '') {
    this.imgSrc.next(src);
  }

  mouseLeave(src: string) {
    this.setThumb(src);
    this.showControls = false;
  }

  mouseEnter(src: string) {
    this.setThumb(src);
    this.showControls = true;
  }
}
