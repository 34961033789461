import moment from 'moment';
import { DateInterval } from '@openreel/common';

export const isNowBetween = (minDate: Date, maxDate: Date) => {
  if (!minDate || !maxDate) {
    return false;
  }

  const now = Date.now();
  return minDate.getTime() <= now && maxDate.getTime() >= now;
};

export const willBeToday = (date: Date) => {
  if (!date) {
    return false;
  }

  const now = new Date();

  return (
    date.getTime() > now.getTime() &&
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
};

export const isFuture = (date: Date) => {
  if (!date) {
    return false;
  }

  return date.getTime() > Date.now();
};

export const humanDateDiff = (date1: Date, date2: Date) => {
  const dateDiff = date1.getTime() - date2.getTime();

  return {
    hours: String(Math.floor(dateDiff / 1000 / 60 / 60)).padStart(2, '0'),
    minutes: String(Math.floor((dateDiff / 1000 / 60) % 60)).padStart(2, '0'),
    seconds: String(Math.floor(dateDiff / 1000) % 60).padStart(2, '0'),
  };
};

const padZerosStart = (num: number, maxLength) => num.toString().padStart(maxLength, '0');

export const formatMillisecondsAsTime = (ms: number, forceHours: boolean = false): string => {
  const h = Math.floor(ms / 3_600_000);
  const m = Math.floor((ms - h * 3_600_000) / 60_000);
  const s = Math.floor((ms - h * 3_600_000 - m * 60_000) / 1_000);
  ms = Math.trunc(ms - h * 3_600_000 - m * 60_000 - s * 1_000);

  return `${h || forceHours ? `${forceHours ? padZerosStart(h, 2) : h}:` : ''}${padZerosStart(
    m,
    h || forceHours ? 2 : 0
  )}:${padZerosStart(s, 2)}.${padZerosStart(ms, 3)}`;
};

export const formatSecondsAsDuration = (seconds: number): string => {
  const timeString = formatMillisecondsAsTime(seconds * 1000);
  const timeWithoutMs = timeString.split('.')[0];
  return timeWithoutMs;
};

export const formatSecondsAsVtt = (seconds: number): string => {
  const timeString = formatMillisecondsAsTime(seconds * 1000, true);
  return timeString;
};

export const relativeDateStr = (date: Date | string): string | null => {
  if (!date) return null;
  const d = moment(date);
  if (!d.isValid()) return null;
  return d.fromNow();
};

/* Returns array of time. Ex: [12:00am, 12:15am]
 */
export const getTimeOnly = (interval: number = 15) => {
  const times = []; // time array
  let tt = 0; // start time
  const ap = ['AM', 'PM']; // AM-PM
  for (var i = 0; tt < 24 * 60; i++) {
    const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    const mm = tt % 60; // getting minutes of the hour in 0-55 format
    let formatedHr = ('0' + (hh % 12)).slice(-2);
    if (formatedHr === '00') {
      formatedHr = '12';
    }
    times[i] = formatedHr + ':' + ('0' + mm).slice(-2) + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + interval;
  }
  return times;
};


export const getDateRange = (interval: DateInterval, defaultValue?: { start: Date | null; end: Date | null }): { start: Date | null; end: Date | null } => {
  switch (interval) {
    case DateInterval.None:
      return { start: null, end: null }
    case DateInterval.Today:
      return { start: moment().toDate(), end: null }
    case DateInterval.LastWeek:
      return { start: moment().subtract(1, 'week').toDate(), end: moment().toDate() }
    case DateInterval.LastMonth:
      return { start: moment().subtract(1, 'month').toDate(), end: moment().toDate() }
    case DateInterval.Custom:
      return { start: defaultValue?.start ?? null, end: defaultValue?.end ?? null }
    default:
      return { start: null, end: null }
  }
}
