import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { HostingListComponent } from './hosting-list/hosting-list.component';
import { VideoCardComponent } from './video-card/video-card.component';
import { HeaderComponent } from './header/header.component';
import { VideoContainerComponent } from './video-container/video-container.component';
import { HostingExceptionHandler } from './services/exception-handler.service';
import { EmbeddedPlayerComponent } from './embedded-player/embedded-player.component';
import { AppContainerComponent } from './app-container/app-container.component';
import { VideoService } from './services/video.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AnalyticsModule } from './analytics/analytics.module';
import { PasswordPageComponent } from './password-page/password-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { MatMenuModule } from '@angular/material/menu';
import { AnalyticsInterceptor } from './analytics/analytics.interceptor';

import { HostingVideoPlayerModule } from '@openreel/frontend/common/hosting/video-player/video-player.module';
import { ImgFallbackDirective } from '@openreel/frontend/common/directives/img-fallback.directive';
import { OrInfiniteScrollComponent } from '@openreel/ui/or-infinite-scroll/or-infinite-scroll.component';
import { HostableLayoutModule } from '@openreel/frontend/common/hosting/hostable-layout/hostable-layout.module';
import { PluralizePipe } from '@openreel/frontend/common/pipes/pluralize.pipe';
import { RecreateOnChangeDirective } from '@openreel/frontend/common/directives/recreate-on-change.directive';
import { WidgetLayoutModule } from '@openreel/frontend/common/hosting/hostable-layout/widget-layout/widget-layout.module';

import { AddTokenInterceptor } from './interceptors/add-token.interceptor';
import { AddCommentComponent } from './public-comments/add-comment/add-comment.component';
import { PublicCommentListComponent } from './public-comments/public-comment-list/public-comment-list.component';
import { PreviewThumbComponent } from '@openreel/frontend/common/hosting/components/preview-thumb/preview-thumb.component';

@NgModule({
  declarations: [
    AppComponent,
    AppContainerComponent,
    HostingListComponent,
    VideoCardComponent,
    HeaderComponent,
    VideoContainerComponent,
    EmbeddedPlayerComponent,
    FooterComponent,
    ErrorPageComponent,
    PasswordPageComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    HostingVideoPlayerModule,
    AppRoutingModule,
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    MatTooltipModule,
    AnalyticsModule,
    ToastrModule.forRoot(),

    HostableLayoutModule,

    AddCommentComponent,
    OrInfiniteScrollComponent,
    WidgetLayoutModule,
    PublicCommentListComponent,
    PluralizePipe,
    ImgFallbackDirective,
    PreviewThumbComponent,
    RecreateOnChangeDirective,
  ],
  providers: [
    { provide: ErrorHandler, useClass: HostingExceptionHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AnalyticsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AddTokenInterceptor, multi: true },
    VideoService,
    ToastrService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
